import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import { ReactElement } from 'react';

import Logo from '@/components/Logo';

interface InitAuthLayoutProps {
  imageSrc: string;
  leftContent: ReactElement;
  subtitle: string | ReactElement;
  title: string;
}

export const InitAuthLayout = ({ imageSrc, leftContent, subtitle, title }: InitAuthLayoutProps) => {
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 20,
      }}
      alignItems="center"
    >
      <Grid item xs={12} md={8}>
        <Stack
          gap={{
            xs: 1,
            md: 1.5,
          }}
          paddingLeft={{
            md: '5rem',
          }}
        >
          <Logo width={107} height={57} />
          <Typography variant="h1">{title}</Typography>

          <Typography fontSize="18px" color="text.secondary">
            {subtitle}
          </Typography>

          <Box
            marginY={1.5}
            sx={{
              width: 'fit-content',
              marginTop: '6rem',
            }}
          >
            {leftContent}
          </Box>
        </Stack>
      </Grid>
      {imageSrc && (
        <Grid
          item
          xs={12}
          md={4}
          display={{
            xs: 'none',
            md: 'flex',
          }}
          justifyContent={'center'}
          alignItems={'center'}
          position={'relative'}
        >
          {/* <div className={styles.GlassMorph} data-mui-color-scheme={theme.palette.mode}></div> */}
          <Image
            src={imageSrc}
            width={0}
            height={0}
            sizes="100vw"
            style={{
              width: '100%',
              height: 'auto',
              position: 'absolute',
              opacity: 0.6,
              filter: 'blur(100px)',
            }}
            alt="Fuul | The web3 growth platform"
          />
        </Grid>
      )}
    </Grid>
  );
};
