import Head from 'next/head';

interface SocialTags {
  description?: string;
  image?: string;
  title?: string;
  url?: string;
}

const socialTags = ({ url, title, description, image }: SocialTags) => {
  const metaTags = [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image:src', content: image },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'og:title', content: title },
    { name: 'og:url', content: url },
    { name: 'og:image', content: image },
    { name: 'og:description', content: description },
  ];

  return metaTags;
};

interface SeoProps {
  description?: string;
  image?: string;
  title?: string;
  url?: string;
}

const Seo = ({
  title = 'Fuul',
  description = 'The Web3 growth platform',
  image = '/assets/logos/fuul-isologo-color.webp',
  url,
}: SeoProps) => {
  const fullTitle = title ? `Fuul | ${title}` : 'Fuul';

  return (
    <Head>
      <title>{fullTitle}</title>
      <link rel="icon" type="image/svg" sizes="32x32" href="/assets/logos/fuul-isologo-color.webp" />
      <link rel="icon" type="image/svg" sizes="16x16" href="/assets/logos/fuul-isologo-color.webp" />
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      {socialTags({
        title,
        description,
        image,
        url,
      }).map(({ name, content }) => {
        return <meta key={name} name={name} content={content} />;
      })}
    </Head>
  );
};

export default Seo;
