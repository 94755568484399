import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import type { FC, ReactNode } from 'react';

import Seo from '@/components/Seo';

const LayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: '100%',
  minHeight: 'calc(100vh - 98px)',
  padding: '49px 64px',
  [theme.breakpoints.down('md')]: {
    padding: '49px 30px',
  },
}));

interface LayoutProps {
  children: ReactNode;
  wide?: boolean;
}

export const AuthLayout: FC<LayoutProps> = (props) => {
  const { children } = props;

  const theme = useTheme();

  return (
    <LayoutRoot className={`bg-gradient-${theme.palette.mode}`}>
      <Seo
        title="Sign in to Fuul"
        description="Welcome to Fuul! Sign in to your account or set up a new profile in a few seconds."
        image="/assets/backgrounds/fuul-cover.webp"
      />
      <Stack component="main" justifyContent="center" flex={1}>
        <Container maxWidth="lg">{children}</Container>
      </Stack>
    </LayoutRoot>
  );
};
