import { Box } from '@mui/material';
import Image from 'next/image';

import { useSettings } from '@/hooks/useSettings';

interface Props {
  width?: number;
  height?: number;
}

export default function Logo({ width = 90, height = 48 }: Props) {
  const settings = useSettings();
  const logoSrc =
    settings.paletteMode === 'dark' ? '/assets/logos/fuul-logo-blanco.webp' : '/assets/logos/fuul-logo-color.webp';

  return (
    <Box
      sx={{
        width,
        height,
        position: 'relative',
      }}
    >
      <Image src={logoSrc} fill alt="Fuul" />
    </Box>
  );
}
