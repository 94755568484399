import { DynamicWidget } from '@dynamic-labs/sdk-react-core';
import { NextPageWithLayout } from 'pages/_app';
import { ReactElement, useEffect } from 'react';
import { AuthLayout } from 'src/layouts/auth/auth-layout';
import { InitAuthLayout } from 'src/layouts/auth/init-auth-layout';
import { useAuth } from 'src/modules/auth/context/AuthContext';

const LoginPage: NextPageWithLayout = (): JSX.Element => {
  const { signIn, isAuthenticated } = useAuth();

  useEffect(() => {
    signIn();
  }, [signIn]);

  if (isAuthenticated) {
    return <></>;
  }

  return (
    <InitAuthLayout
      title="Accelerate your growth"
      subtitle="Sign in with your wallet or email to start your journey"
      imageSrc="/assets/logos/fuul-logo-3d.webp"
      leftContent={<DynamicWidget innerButtonComponent={<span>Sign in</span>} />}
    />
  );
};

export default LoginPage;

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <AuthLayout>{page}</AuthLayout>;
};
